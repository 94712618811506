import { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { generateDestinationTitle } from "app/utils/productUtils";

const ProductDestinationTitleComponent = ({ region, resort, className, shop, country }) => {
	const destination = generateDestinationTitle(shop, country, region, resort);

	return (
		<Typography
			className={classNames("product-destination-title", className)}
			variant={TYPOGRAPHY_VARIANTS.XL}
			variantMD={TYPOGRAPHY_VARIANTS.XL3}
			isBold
			component={"span"}
		>
			{destination}
		</Typography>
	);
};

ProductDestinationTitleComponent.propTypes = {
	country: PropTypes.string,
	shop: PropTypes.string,
	region: PropTypes.string,
	resort: PropTypes.string,
	className: PropTypes.string,
};

export const ProductDestinationTitle = memo(ProductDestinationTitleComponent);
